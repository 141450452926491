.swiper-pagination {
 position: static;
 margin: 5px auto;
}

.swiper-pagination-bullet {
 width: 12px;
 height: 12px;
 background: #D9D9D9;
 opacity: 0.5;
 transition: opacity 0.3s ease;
}

.swiper-pagination-bullet-active {
 background-color: #150C09;
 opacity: 1;
 transform: scale(1.2);
}