.banner {
  display: flex;
  width: 100%;
  height: 3rem;
  padding: 0.5rem 0rem 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.inner {
  color: #fff;
}

.banner a {
  font-family: "Nunito Sans";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
}

.banner a:hover {
  text-decoration-line: none;
}
