@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --heygg-primary-color: #57E197;
}

body {
  font-family: "Nunito Sans", "Clear Sans", "Helvetica Neue", Arial, sans-serif;
}

.bg-header-announcement {
  background: #e2e8f0;
}

.darkmode .bg-header-announcement {
  background: #555;
}

.colorblind .correct {
  background: #3087EF !important;
}

.colorblind .correct {
  border-color: #3087EF !important;
}

.colorblind .present {
  background: #E2B53F !important;
}

.colorblind .present {
  border-color: #E2B53F !important;
}

.disablecolorblind {
  display: none;
}

.colorblind .disablecolorblind {
  display: block;
}

.colorblind .enablecolorblind {
  display: none;
}

.colorblind .absent {
  background-color: #94a3b8;
  border-color: #94a3b8;
}

.darkmode {
  background: #141214;
  color: #fffefe;
}

.disabledarkmode {
  display: none;
}

.darkmode .disabledarkmode {
  display: block;
}

.darkmode .enabledarkmode {
  display: none;
}

.darkmode .bg-white {
  background: #141214;
}

.no-darkmode {
  color: #000000;
}

.cell-animation {
  animation: revealCharCell linear;
  animation-duration: 0.15s;
}

@keyframes revealCharCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.instructions {
  display: block;
  padding: 24px;
  max-width: 440px;
  border-radius: 8px;
  text-align: left;
  margin-top: 20px;
}

.instructions h2 {
  font-family: "Nunito Sans", "Clear Sans", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 120%;
  align-items: center;
  text-align: center;
  order: 0;
  padding-bottom: 1em;
}

.instructions h3 {
  font-family: "Nunito Sans", "Clear Sans", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 120%;
  align-items: left;
  text-align: left;
  order: 0;
  padding-bottom: 1em;
}

.instructions h3.center {
  align-items: center;
  text-align: center;
}

.feedback {
  margin: 25px auto 15px auto;
  height: 51px;
  display: flex;
  justify-content: center;
}

.feedback a {
  text-align: center;
  text-decoration: underline;
}

.feedback a:hover {
  text-decoration: none;
}

.instructions p {
  font-family: "Nunito Sans", "Clear Sans", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  padding-bottom: 1em;
}

.instructions ul {
  list-style-type: disc;
  font-family: "Nunito Sans", "Clear Sans", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  padding: 0 1em 1em;
}

.instructions img {
  width: 100%;
}

#div-gpt-ad-1682360796626-0 {
  min-width: 300px;
  min-height: 50px;
  max-width: 320px;
  margin: 0 auto;
}

#div-gpt-ad-1684448693454-0 {
  min-width: 728px;
  min-height: 90px;
  max-width: 970px;
  margin: 0 auto;
}

#div-gpt-ad-1684448715375-0 {
  min-width: 300px;
  min-height: 250px;
  max-width: 336px;
  margin: 0 auto;
}

#div-gpt-ad-1684448661796-0 {
  min-width: 160px;
  min-height: 600px;
}

.bottom-fixed-desktop {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 95px;
  background: #fff;
  margin: 0 auto;
  z-index: 99;
}

.bottom-fixed-desktop .inner {
  margin: 5px auto 0;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-fixed-mobile {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 55px;
  background: #fff;
  margin: 0 auto;
  display: none;
  z-index: 99;
}

.darkmode .bottom-fixed-desktop,
.darkmode .top-fixed-mobile {
  background: #141214;
}

.top-fixed-mobile .inner {
  margin: 0 auto 5px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

body {
  margin: 0 0 95px;
}

/*.container, .instructions {
  margin: 0 auto;
  max-width: 440px;
}

.hide-instructions .container, .hide-instructions .instructions {
  max-width: 485px;
}*/

.two-col-layout {
  display: flex;
}

.two-col-layout .right-sidebar {
  width: 160px;
  margin: 20px 0 0 50px;
}

.right-sidebar {
  position: absolute;
  width: 160px;
  height: 600px;
  top: 20px;
  right: 20px;
}

.below-game-footer-mobile {
  display: none;
  margin: 0 auto;
}

.below-game-footer-mobile .inner {
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticky-footer-tall {
  position: sticky;
  bottom: 0;
  margin: 0 auto;
}

.sticky-footer-tall .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.sticky-footer-short {
  position: sticky;
  bottom: 0;
  margin: 0 auto;
  display: none;
}

.sticky-footer-short .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

#fuse-injected-23149732595-1 {
  bottom: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.sticky-pushdown {
  background-color: #f5f7fa;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;

  @media (min-width:900px) {
    min-height: 100px;
    display: none;
  }

  @media (max-width:899px) and (min-height: 850px) {
    min-height: 100px;
  }

  @media (max-width:899px) and (max-height: 849px) {
    min-height: 50px;
    max-height: 50px;
    height: 50px;
  }

  @media (max-width:899px) and (max-height: 650px) {
    display: none;
  }
}

.darkmode .sticky-pushdown {
  background-color: #141214;
}

.sticky-siderail-left .inner {
  display: flex;
  width: 300px;
}



.sticky-siderail-right .inner {
  display: flex;
  width: 300px;
}

.incontent-bottom {
  display: flex;
  justify-content: center;
  background-color: transparent;
  margin-bottom: 15px;
}

.navLink {
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.navLink:hover {
  text-decoration: underline;
}

.navLink-inactive {
  opacity: 0.4;
}

.toggle-label {
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.sticky-leaderboard-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

.sticky-leaderboard-footer .inner {
  display: flex;
}

.sticky-sticky-pushdown {
  top: 0;
  text-align: center;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {

  .sticky-siderail-left,
  .sticky-siderail-right,
  #mathler_siderail_left,
  #mathler_siderail_right,
  #mathler_siderail_left div,
  #mathler_siderail_right div,
  #mathler_siderail_left div iframe,
  #mathler_siderail_right div iframe {
    width: 160px;
    overflow: hidden;
  }
}

@media (max-width: 899px) {
  /*.container, .instructions, .hide-instructions .container, .hide-instructions .instructions {
    max-width: 500px;
  }*/

  .bottom-fixed-desktop {
    display: none;
  }

  .sticky-siderail-left,
  .sticky-siderail-right,
  .right-sidebar {
    display: none;
  }

  /*.two-col-layout .right-sidebar {
    display: none;
  }*/

  .top-fixed-mobile {
    display: block;
  }

  .below-game-footer-mobile {
    display: block;
  }
}

/* Overrides of heygg.css for Freestar placements */
.heygg-body .footer .container {
  max-width: 760px;
}

.heygg-body .heygg-body {
  margin-bottom: 0;
}

@media (max-width: 899px) {
  .heygg-body .heygg-body {
    margin-top: 0;
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .heygg-body .footer .container {
    max-width: 700px;
  }

  .heygg-body .footer .aboutus {
    max-width: 260px;
  }
}

@media (min-height: 850px) {
  .sticky-footer-tall {
    display: block;
  }

  .sticky-pushdown-tall {
    display: block;
  }
}

@media (max-height: 849px) and (min-height: 725px) {
  .sticky-footer-tall {
    display: block;
  }

  .sticky-pushdown-short {
    display: block;
  }
}

@media (max-height: 724px) and (min-height: 650px) {
  .sticky-footer-short {
    display: block;
  }

  .sticky-pushdown-short {
    display: block;
  }
}

@media (max-height: 649px) {
  .sticky-pushdown-short {
    display: block;
  }
}

@media (min-width: 900px) {
  .sticky-siderail-left {
    display: inline-flex;
  }

  .sticky-siderail-right {
    display: inline-flex;
  }
}

.below-game-footer-mobile {
  overflow: hidden;
}

.darkmode .border-\[\#fff\] {
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.darkmode .border-black {
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.darkmode .bg-\[\#D0D0D0\] {
  background-color: rgb(128 128 128 / var(--tw-bg-opacity));
}

@media (max-height: 650px) {
  .my-4 {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}