.toggle {
  width: 40px;
  height: 26px;
  background-color: #d4ddeb;
  border-radius: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 2px;
}

.slider {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  transition: transform 0.3s;
}

.toggle.on .slider {
  transform: translateX(18px);
}

.toggle.off .slider {
  transform: translateX(2px);
}

.toggle.off {
  background-color: #d4ddeb;
}

.toggle.on {
  background-color: #5ec26a;
}
